import type DataGridControl from './DataGridControl.ts';
import { InjectionKeys } from 'o365-utils';
import { defineComponent, inject, markRaw, onMounted, onBeforeUnmount, ref, h } from 'vue';
import { Debouncer } from 'o365-utils';
import DataColumn from './DataGrid.DataColumn.ts';

const dataGridControlKey = InjectionKeys.dataGridControlKey
declare module './DataGrid.DataColumn.ts' {
    interface DataColumn {
        autoHeightApi: typeof ColumnAutoHeightApi
    }
}
declare module './DataGridControl.ts' {
    interface DataGridControl {
        _autoHeightStore?: Record<number, Record<string, number>>
    }
}

Object.defineProperties(DataColumn.prototype, {
    'autoHeightApi': {
        get() {
            return ColumnAutoHeightApi;
            // if (this._autoHeightApi = new ColumnAutoHeightApi();
            // }== null) {
            //     this._autoHeightApi = new ColumnAutoHeightApi();
            // }
            // return this._autoHeightApi;
        }
    },
});

type AutoHeightCellCtx = {
    slots: {
        default: (bindgins: {
            target: (pElement: HTMLElement | null) => void
        }) => void
    },
};

type AutoHeightCellProps = {
    column: DataColumn,
    row: Object,
};

const AutoHeightCell = markRaw(defineComponent({
    name: 'AutoHeightCell',
    props: {
        column: Object,
        row: Object,
    },
    setup(props: AutoHeightCellProps, ctx: AutoHeightCellCtx) {
        const dataGridControl: { value?: DataGridControl } = inject(dataGridControlKey);
        if (dataGridControl == null || dataGridControl.value == null) {
            throw new TypeError('AutoHeightCell cannot be used outside of data grid');
        }
        const cellRef: { value: HTMLElement | null } = ref(null);
        const uid = crypto.randomUUID();
        const rowStoreId = `${dataGridControl.value.id}_${props.row._index}`;

        const cellObserver = getAutoHeightCellObserver(uid, dataGridControl.value, () => props, rowStoreId);

        onMounted(() => {
            if (cellRef.value) {
                cellObserver.setElement(cellRef.value);
                cellObserver.observe();
            }
        });

        // onBeforeUnmount(() => {
        //     cellObserver.unobserve();
        //     cellObserver.destroy();
        // });
        onBeforeUnmount(() => {
            cellObserver.unobserve();
            cellObserver.destroy();
        });
        // return () => ctx.slots.default({target: el => cellRef.value = el});
        return () => h('div', {
            class: 'o365-autoheight-wrapper d-flex text-wrap',
            ref: el => cellRef.value = el
        }, ctx.slots.default({ target: el => cellRef.value = el }));
    },
}));

const rowStore = new Map<string, Record<string, number>>();

const autoHeightCellObserverStore = new Map<string, AutoHeightCellObserver>();
function getAutoHeightCellObserver(pUid: string, pDataGridControl: DataGridControl, pGetProps: () => AutoHeightCellProps, pRowStoreId: string) {
    if (!autoHeightCellObserverStore.has(pUid)) {
        autoHeightCellObserverStore.set(pUid, new AutoHeightCellObserver(pDataGridControl, pGetProps, pRowStoreId));
    }
    return autoHeightCellObserverStore.get(pUid)!;
}

class AutoHeightCellObserver {
    private _dataGridControl: DataGridControl;
    private _getProps: () => AutoHeightCellProps;
    private _element?: HTMLElement;
    private _observer: ResizeObserver;

    private _height?: number;
    private _rowStoreId: string;

    get minHeight() { return 34; }

    constructor(pDataGridControl: DataGridControl, pGetProps: () => AutoHeightCellProps, pRowStoreId: string) {
        this._dataGridControl = pDataGridControl;
        this._getProps = pGetProps;
        this._rowStoreId = pRowStoreId;
        const debouncer = new Debouncer(50);
        this._observer = new ResizeObserver((entries) => {

            debouncer.run(() => {
                entries.forEach(entry => {

                    const target = entry.target;
                    const sizes = getElementSize(target.parentElement);
                    let scrollHeight = target.scrollHeight + sizes.paddingTop + sizes.paddingBottom;
                    if (scrollHeight <= this.minHeight) { scrollHeight = this.minHeight; }
                    this.storeHeight(scrollHeight);
                    scrollHeight = this.getMaxHeight();
                    if (this._height == scrollHeight) { return; }
                    this._height = scrollHeight;
                    const props = this._getProps();
                    // Math.ceil(scrollHeight)
                    if (props?.row?.updateSize) {
                        props.row.updateSize(scrollHeight)
                        // props.row.updateSize(Math.ceil(scrollHeight))
                        // console.log(Math.ceil(scrollHeight), 'Height;  ', props.row.item.ID,' ID;   '  , entry.target,' Target;  ')



                    }
                });
            });
        });
    }

    setElement(pElement: HTMLElement) {
        this._element = pElement;
    }
    observe() {
        if (this._element == null) { return; }
        // return;
        this._observer.observe(this._element);
    }
    unobserve() {
        if (this._element == null) { return; }
        // return;
        this._observer.unobserve(this._element);
        rowStore.delete(this._rowStoreId);
    }

    destroy() {
        this._observer.disconnect();
    }

    storeHeight(pHeight: number) {
        if (!rowStore.has(this._rowStoreId)) { rowStore.set(this._rowStoreId, {}) };
        const rowHeights = rowStore.get(this._rowStoreId)!;
        const colId = this._getProps().column.colId as string;
        rowHeights[colId] = pHeight;
    }

    getMaxHeight() {
        const rowHeights = rowStore.get(this._rowStoreId) ?? {};
        return Object.entries(rowHeights).reduce((maxHeight, entry) => {
            return Math.max(maxHeight, entry[1]);
        }, this.minHeight);

    }
}

const ColumnAutoHeightApi = {
    getCellComponent() { return AutoHeightCell; }
}

function getElementSize(el: HTMLElement): {
    height: number;
    width: number;
    borderTopWidth: number;
    borderRightWidth: number;
    borderBottomWidth: number;
    borderLeftWidth: number;
    paddingTop: number;
    paddingRight: number;
    paddingBottom: number;
    paddingLeft: number;
    marginTop: number;
    marginRight: number;
    marginBottom: number;
    marginLeft: number;
    boxSizing: string;
} {
    const {
        height,
        width,
        borderTopWidth,
        borderRightWidth,
        borderBottomWidth,
        borderLeftWidth,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        boxSizing,
    } = window.getComputedStyle(el);

    return {
        height: parseFloat(height || '0'),
        width: parseFloat(width || '0'),
        borderTopWidth: parseFloat(borderTopWidth || '0'),
        borderRightWidth: parseFloat(borderRightWidth || '0'),
        borderBottomWidth: parseFloat(borderBottomWidth || '0'),
        borderLeftWidth: parseFloat(borderLeftWidth || '0'),
        paddingTop: parseFloat(paddingTop || '0'),
        paddingRight: parseFloat(paddingRight || '0'),
        paddingBottom: parseFloat(paddingBottom || '0'),
        paddingLeft: parseFloat(paddingLeft || '0'),
        marginTop: parseFloat(marginTop || '0'),
        marginRight: parseFloat(marginRight || '0'),
        marginBottom: parseFloat(marginBottom || '0'),
        marginLeft: parseFloat(marginLeft || '0'),
        boxSizing,
    };
}